var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "ui single line table fixed-header" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _vm._l(_vm.thead, function(head, i) {
            return _c("th", {
              key: i,
              staticClass: "headers",
              domProps: { innerHTML: _vm._s(head) }
            })
          }),
          _c("th", { staticClass: "headers extra-options" }, [_vm._v(" ")])
        ],
        2
      )
    ]),
    _c(
      "tbody",
      [
        _vm.loading
          ? _c(
              "tr",
              _vm._l([].concat(Array(6)), function(item, k) {
                return _c(
                  "td",
                  { key: k },
                  [_c("t-placeholder", { attrs: { lines: 1 } })],
                  1
                )
              }),
              0
            )
          : _vm._l(_vm.deposits, function(items, i) {
              return _c(
                "tr",
                { key: i },
                [
                  _vm._l(items, function(item, k) {
                    return _c("td", {
                      key: k,
                      staticClass: "columns",
                      domProps: { innerHTML: _vm._s(item) }
                    })
                  }),
                  _c(
                    "td",
                    { staticClass: "center aligned columns extra-options" },
                    [
                      _c("t-dropdown", {
                        attrs: {
                          icon: ["fas", "ellipsis-h"],
                          extraClass: "top right",
                          pointing: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function() {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "item",
                                      attrs: { href: "#" }
                                    },
                                    [_vm._v("menu item")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "item",
                                      attrs: { href: "#" }
                                    },
                                    [_vm._v("menu item")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "item",
                                      attrs: { href: "#" }
                                    },
                                    [_vm._v("menu item")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "item",
                                      attrs: { href: "#" }
                                    },
                                    [_vm._v("menu item")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                2
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }