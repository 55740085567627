export default {
  name: 'TExtractTable',
  data () {
    return {
      thead: ['Data', '$ Em doações', 'Taxas', '$ Transferido']
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    deposits: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    bindPlugins () {
      $('.tui.ui.dropdown').dropdown()
    }
  },
  mounted () {
    this.bindPlugins()
  }
}
