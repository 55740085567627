import { mapActions } from 'vuex'
import TFilter from '@/components/filter'
import TStatusBar from '@/components/status/bar'
import TStatusBarItem from '@/components/status/item.vue'
import TExtractTable from '@/components/extract/table.vue'
import { currencyFormat, formatDate } from '@/helpers'

export default {
  name: 'TExtractPage',
  title: ({ title }) => title,
  data () {
    return {
      title: this.$tc('Extract'),
      total: 3216,
      statusItems: [
        { label: 'Bruto', value: '207.190,00', itemClass: 'success' },
        { label: 'Taxa', value: '207.190,00', icon: 'minus', itemClass: 'light-fault' },
        { label: 'Total', value: '207.190,00', icon: 'equals' }
      ],
      deposits: [...Array(20)].map((v, i) => ({
        date: formatDate(faker.date.between('2019-08-01', '2019-07-01'), 'short'),
        donations: currencyFormat(faker.random.number(99999),),
        tax: currencyFormat(faker.random.number(99999),),
        total: currencyFormat(faker.random.number(999999),)
      })),
    }
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
  },
  mounted() {
    this.changeBreadcrumbItems([
      { to: { name: 'extract' }, title: 'Extrato', active: true }
    ])
  },
  components: {
    TStatusBar,
    TStatusBarItem,
    TExtractTable,
    TFilter
  }
}
