var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: { title: _vm.$tc("Extract") },
        scopedSlots: _vm._u([
          {
            key: "bottom",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "tui tuim ui top attached tabular menu" },
                  [
                    _c("div", { staticClass: "tui tuim ui container" }, [
                      _c(
                        "a",
                        { staticClass: "item active", attrs: { href: "#" } },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s("Atual") }
                          })
                        ]
                      ),
                      _c("a", { staticClass: "item", attrs: { href: "#" } }, [
                        _c("span", [
                          _vm._v("\n              Futuro "),
                          _c("small", {
                            domProps: { textContent: _vm._s("BRL 20.000") }
                          })
                        ])
                      ])
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c("t-page-sidebar", [_c("t-filter")], 1),
          _c(
            "t-page-content",
            [
              false
                ? _c(
                    "t-status-bar",
                    {
                      attrs: {
                        title: "<strong>" + _vm.total + "</strong> depósitos"
                      }
                    },
                    _vm._l(_vm.statusItems, function(item, k) {
                      return _c("t-status-bar-item", {
                        key: k,
                        attrs: { item: item }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c("t-extract-table", { attrs: { deposits: _vm.deposits } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }